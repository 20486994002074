.App {
  text-align: center;
}

.Align-Left {
  text-align: left;
}

.Align-Center {
  text-align: center;
}

.App-box {
  vertical-align: top;
  position: relative;
  top: -140px;
  width: 100%;
  height: auto;
  z-index:-5;
}

.App-panel {
  top: -100px;
  position: relative;
  width: 70%;
  height: auto;
}

.Contacts-box{
  vertical-align: top;
  position: relative;
  top: -140px;
  width: 100%;
  height: auto;
  z-index:-5;
}

.Float-Left {
  float: left;
  vertical-align: top;
  position: relative;
  max-width: 40%;
  top: 180px;
  left: 80px;
}

.Float-Right {
  float: right;
  vertical-align: top;
  position: relative;
  max-width: 40%;
  top: 200px;
  left: -80px;
}

.Top-Margin {
  top: 15px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
